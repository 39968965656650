// Styles
import React, { useState, SyntheticEvent, useEffect, Fragment } from 'react';
import { AccordionItem, AccordionProps } from '../../accordion.interface';
import {
    AccordionItemsStyled,
} from '../../accordion.styles';
import {
    AccordionSummaryTextStyled,
    MuiAccordionDetailsStyled,
    MuiAccordionStyled,
    MuiAccordionSummaryStyled,
    AccordionImageContainerStyled,
    AccordionDetailImageStyled,
    AccordionAddtionalInfoContentStyled,
    AccordionAddtionalInfoStyled,
    AccordionLoadingBarStyled,
    AccordionLoadingBarLineStyled,
    AccordionAdditionalInfoTitleStyled,
    AccordionDetailTextStyled,
    AccordionIconTitleStyled,
    AccordionMobileDetailImageStyled,
    AccordionMobileDetailImageContainer,
    AccordionDetailImageContanerStyled,
    AccordionIndicatorStyled,
    VideoPlayerContainer,
    VideoMobilePlayerContainer,
    CTAContainer
} from './vertical-accordion.styles';
import { RichText, FeaturesVideoPlayer } from '@shared/components';
import useIsMobile from '../../accordion-utils';
import { default_breakpoints } from '@src/styles/breakpoints';
import { PrimaryButton } from '@shared/components';

export const VerticalAccordion = ({
    variant = 'light',
    itemList,
    template,
    summarySize = 'normal',
    additionalInfo,
    layout
}: AccordionProps) =>{
        
    const hasAdditionalInfo =
    additionalInfo && (additionalInfo?.title || additionalInfo?.content);

    const [ expanded, setExpanded ] = useState<number | 0>(0);
    const [ autoSlide, setAutoSlide ] = useState<boolean | false>(true);
    const [ currentSlideProgress, setCurrentSlideProgress ] = useState<number | 0>(0);
    const DURATION = 6000;
    const isMobile = useIsMobile();

    const handleChange = (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
      if(panel !== expanded) {
        setCurrentSlideProgress(0);
        setExpanded(isExpanded ? panel : 0);
      }
    };

    const handleMouseOver = (panel?: number) => (event: SyntheticEvent) => {
      if(panel === expanded){
        setAutoSlide(false);
      }
    };
    const handleMouseLeave = (panel?: number) => (event: SyntheticEvent) => {
      if(panel === expanded){
        setAutoSlide(true);
      }
    };
  
    useEffect(()=>{
      const slidingInterval = setInterval(function () {
        if(!autoSlide || isMobile || document.getElementById('modal')) {
          return;
        }
        if(currentSlideProgress >= 100) {
          setExpanded((expanded + 1 >= itemList.length) ? 0 : (expanded + 1));
          setCurrentSlideProgress(0);
        } else {
          setCurrentSlideProgress(currentSlideProgress + (100 * 200 / DURATION));
        }
      }, 200);
      
      return () => clearInterval(slidingInterval);
    }, [ itemList.length, autoSlide, expanded, currentSlideProgress, isMobile ]);

    return ( 
        <>
        <AccordionItemsStyled 
          layout={layout} 
          data-testid="accordion__items" 
          template={template?.toLocaleLowerCase()}
          className={autoSlide ? '' : 'slider-paused'}
          >
            {itemList.map((item: AccordionItem, index:number) => {
              return (
                <MuiAccordionStyled
                  disableGutters
                  elevation={0} 
                  square
                  TransitionProps={{ unmountOnExit: true }}
                  key={`accordion-item_${index}`}
                  expanded={expanded === index} 
                  onChange={handleChange(index)}
                  onMouseOver={handleMouseOver(index)}
                  onMouseLeave={handleMouseLeave(index)}
                >
                  {expanded === index && (
                    <AccordionLoadingBarStyled variant={variant}>
                      <AccordionLoadingBarLineStyled style={{ width: `${isMobile ? 100 : currentSlideProgress}%` }}/>
                    </AccordionLoadingBarStyled>
                  )}
                  <MuiAccordionSummaryStyled
                    size={summarySize}
                    aria-controls={`panel_${index}`}
                    id={`panel_${index}`}
                    expandIcon={
                      <AccordionIndicatorStyled viewBox="0 0 32 32">
                        {expanded === index ? (
                          <path fillRule="evenodd" clipRule="evenodd" d="M17.3333 14.6667H24V17.3334H17.3333H16.5H15.5H14.6667H8V14.6667H14.6667H15.5L16.5 14.6667L17.3333 14.6667Z" fill="#25C9EF"/>
                        ) : (
                          <path fillRule="evenodd" clipRule="evenodd" d="M17.3333 14.6667H24V17.3333H17.3333V24H14.6667V17.3333H8V14.6667H14.6667V8H17.3333V14.6667Z" fill="#25C9EF"/>
                        )}
                      </AccordionIndicatorStyled>
                    }
                  >
                    <AccordionSummaryTextStyled
                      size={summarySize}
                      variant={variant}
                    >
                      <AccordionIconTitleStyled 
                        src={item.feature_icon.image.url}
                        alt={item.feature_icon.image_alt_text}
                      />
                      {item.summary}
                    </AccordionSummaryTextStyled>
                  </MuiAccordionSummaryStyled>
                  <MuiAccordionDetailsStyled>
                    <AccordionDetailTextStyled 
                      size={summarySize} 
                      variant={variant} 
                      template={template?.toLocaleLowerCase()}
                      className={expanded === index ? 'details__expanded' : ''}
                    >
                      <RichText content={item.details} />
                      {(item.link.href && item.link.title) && (
                        <CTAContainer template={template?.toLocaleLowerCase()}>
                          <PrimaryButton
                            ariaLabel={item.link.title}
                            path={item.link.href}
                            label={`${item.link.title}`}
                            size={'large'}
                            variant={'contained'}
                            icon={'text'}
                          />
                        </CTAContainer>
                      )}
                      <AccordionMobileDetailImageContainer template={template?.toLocaleLowerCase()}>
                        <picture>
                          <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={item.feature_image.image.url} />
                          <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={item.tablet_feature_image.image.url} />
                          <AccordionMobileDetailImageStyled 
                            src={item.mobile_feature_image.image.url}
                            alt={item.feature_image.image_alt_text}
                            template={template?.toLocaleLowerCase()}
                          />
                        </picture>
                        {(item.video_id && expanded === index) && 
                            <VideoMobilePlayerContainer>
                              <FeaturesVideoPlayer 
                                id={item?.video_id} 
                                mobileHeight={'100%'} 
                                height={'45%'} 
                                fullwidth={true} 
                                playingAsPopup={true}
                              />
                            </VideoMobilePlayerContainer>
                        }
                      </AccordionMobileDetailImageContainer>
                    </AccordionDetailTextStyled>
                  </MuiAccordionDetailsStyled>
                </MuiAccordionStyled>
              );
            })}
            {hasAdditionalInfo && (
              <AccordionAddtionalInfoStyled>
                <AccordionAdditionalInfoTitleStyled variant={variant}>
                  {additionalInfo?.title}
                </AccordionAdditionalInfoTitleStyled>
                <AccordionAddtionalInfoContentStyled variant={variant}>
                  <RichText content={additionalInfo?.content} />
                </AccordionAddtionalInfoContentStyled>
              </AccordionAddtionalInfoStyled>
            )}
          </AccordionItemsStyled>
          <AccordionImageContainerStyled>
          {itemList.map((item: AccordionItem, index: number) => (
            <Fragment key={index}>
              <AccordionDetailImageContanerStyled template={template?.toLocaleLowerCase()} className={expanded === index ? 'active' : ''}>
                <picture>
                  <source media={`(min-width:${default_breakpoints.lg}px)`} srcSet={item.feature_image.image.url} />
                  <source media={`(min-width:${default_breakpoints.md}px)`}  srcSet={item.tablet_feature_image.image.url} />
                  <AccordionDetailImageStyled 
                    src={item.mobile_feature_image.image.url}
                    alt={item.feature_image.image_alt_text}
                    onMouseOver={handleMouseOver(index)}
                    onMouseLeave={handleMouseLeave(index)}
                  /> 
                </picture>
              </AccordionDetailImageContanerStyled>
              {(item.video_id && expanded === index) && 
                <VideoPlayerContainer
                  onMouseOver={handleMouseOver(index)}
                  onMouseLeave={handleMouseLeave(index)}
                >
                  <FeaturesVideoPlayer 
                    id={item?.video_id} 
                    mobileHeight={'100%'} 
                    height={'50%'} 
                    fullwidth={true} 
                    playingAsPopup={true}
                  />
                </VideoPlayerContainer>
              }
            </Fragment>
          ))}
        </AccordionImageContainerStyled>
       </>
    )
}