import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { getStyleBorder, getStyleSizes } from '@styles/styles.utils';
import { mq_min, mq_max } from '@styles/breakpoints';

import {
    color_black,
    color_dark_grey,
    color_text_grey,
    color_darkest_grey,
    color_white,
    color_miami,
  } from '@styles/colors';
  import {
    AccordionDetailTextStyledProps,
    AccordionSummaryTextStyledProps,
    MuiAccordionSummaryStyledProps,
    VariantProp,
} from '@shared/components/accordion/accordion.interface';
import { AccordionLayoutProps } from '../../accordion.interface';

import { AccordionTitleStyled } from '../../accordion.styles';

export const variants = {
    light: {
      headlineColor: color_darkest_grey,
      detailColor: color_dark_grey,
      iconColor: color_black,
    },
    mid: {
      headlineColor: color_darkest_grey,
      detailColor: color_dark_grey,
      iconColor: color_black,
    },
    dark: {
      headlineColor: color_white,
      detailColor: color_text_grey,
      iconColor: color_white,
    },
};

export const MuiAccordionStyled = styled(MuiAccordion)`
  position: relative;
  transition: height 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  overflow: hidden;
  padding: ${getStyleSizes([16,25,16])};
  border: none;
  max-width: calc(100% - ${getStyleSizes(48)});
  margin: auto;
  ${mq_min('md')} {
    max-width: calc(100% - ${getStyleSizes(64)});
    padding: ${getStyleSizes([16,25,16])};
  }
  ${mq_min('lg')} {
    padding: ${getStyleSizes([16,24,16])};
    max-width: 100%;
  }

  &:before{
    content: "";
    width: 100%;
    height: 1px;
    background: #e8ebf2;
    top: 0px;
    left: 0px;
    position: absolute;
  }
  &.Mui-expanded{
    padding-bottom: ${getStyleSizes(14)};
  }
`;

export const AccordionLoadingBarStyled = styled('div')<VariantProp>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: ${getStyleSizes(3)};
  background: ${({ variant }) => variants[variant]?.iconColor};
  pointer-events: none;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const AccordionLoadingBarLineStyled = styled('div')`
  position: absolute;
  top: 0;
  left: 0px;
  width: 0px;
  height: 100%;
  background: ${color_miami.base};
  transition: width 0.2s linear;
`

export const MuiAccordionSummaryStyled = styled(
  MuiAccordionSummary
)<MuiAccordionSummaryStyledProps>`
  padding-left: 0;
  padding-right: 0;
  position: relative;

  .MuiAccordionSummary-content{
    margin: 0 !important;
  }
`;

export const MuiAccordionDetailsStyled = styled(MuiAccordionDetails)`
  padding: ${getStyleSizes([0, 0, 0, 0])};
  border-top: 0;
`;

export const AccordionSummaryTextStyled = styled(
  'div'
)<AccordionSummaryTextStyledProps>`
  line-height: ${getStyleSizes(30)};
  font-weight: 600;
  font-size: ${({ size }) => getStyleSizes(size === 'large' ? 20 : 18)};
  color: ${({ variant }) => variants[variant]?.headlineColor};
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding-right: ${getStyleSizes(35)};
  ${mq_min('md')} {
    padding-right: 0;
  }
  ${mq_min('lg')} {
    ${({ size }) =>
      size === 'large' &&
      `
      font-size: ${getStyleSizes(20)};
    `}
  }
`;
export const AccordionDetailTextStyled = styled(
  'div'
)<AccordionDetailTextStyledProps & AccordionLayoutProps>`
  font-size: ${({ size }) =>
    size === 'large' ? getStyleSizes(16) : getStyleSizes(14)};
  line-height: ${getStyleSizes(20)};
  color: ${({ variant }) => variants[variant]?.detailColor};
  min-height: ${getStyleSizes(175)};

  div[data-testid="rich-text__container"]{
    padding-right: ${getStyleSizes(32.5)};
    ${mq_min('lg')} {
      padding-right: ${getStyleSizes(0)};
    }
    p:not(:last-child){
    ${({ template }) => (template === 'needles' || template === 'trialworks') ? `
      margin-bottom: ${getStyleSizes(16)};
    ` : `
      margin-bottom: ${getStyleSizes(27)};
    `};
    }
    p,
    span{
      color: ${color_dark_grey};
      font-style: normal;
      font-weight: 400;
      ${({ template }) => (template === 'needles' || template === 'trialworks') ? `
        font-size: ${getStyleSizes(16)};
        line-height: ${getStyleSizes(24)};
      ` : `
        font-size: ${getStyleSizes(18)};
        line-height: ${getStyleSizes(27)};
      `};
    }
  }

  &.details__expanded{
    padding-top: ${getStyleSizes(5)};
    ${mq_min('md')} {
      padding-top: ${getStyleSizes(9)};
    }
  }
`;

export const AccordionAddtionalInfoStyled = styled('div')`
  border-top: ${getStyleBorder()};
  padding-top: ${getStyleSizes(34)};
`;

export const AccordionAdditionalInfoTitleStyled = styled(
  AccordionTitleStyled
)<VariantProp>`
  color: ${({ variant }) => variants[variant]?.headlineColor};
  font-weight: 600;
`;

export const AccordionAddtionalInfoContentStyled = styled('div')<VariantProp>`
  &,
  a {
    color: ${({ variant }) => variants[variant]?.detailColor};
  }
  * {
    padding: 0;
  }
`;

export const AccordionIconTitleStyled = styled('img')`
  width: ${getStyleSizes(30)};
  height: ${getStyleSizes(30)};
  margin-right: ${getStyleSizes(12)};
`;

export const AccordionMobileDetailImageContainer = styled('div')<AccordionLayoutProps>`
  position: relative;
  ${({ template }) => (template === 'needles' || template === 'trialworks') && `
    background: rgba(28, 28, 28, 0.05);
    border-radius: 3.2px;
    ${mq_min('md')} {
      border-radius: 8px;
    }
  `};
`

export const AccordionMobileDetailImageStyled = styled('img')<AccordionLayoutProps>`
  margin-top: ${getStyleSizes(48)};
  margin-bottom: ${getStyleSizes(41)};
  max-width: 100%;
  width: 100%;
  will-change: filter;
  display: block;
  ${({ template }) => (template === 'needles' || template === 'trialworks') ? `
    border-radius: 3.2px;
    ${mq_min('md')} {
      border-radius: 8px;
    }
  `: `
    filter: drop-shadow(7px 7px 9px rgba(53, 62, 91, 0.25));
  `};
  ${mq_min('md')} {
    margin-top: ${getStyleSizes(48)};
    margin-bottom: ${getStyleSizes(9)};
  }
  ${mq_min('lg')} {
    display: none;
  }
`

export const AccordionDetailImageContanerStyled = styled('div')<AccordionLayoutProps>`
  position: absolute;
  opacity: 0;
  transition: 0.3s opacity cubic-bezier(0.22, 1, 0.36, 1);
  z-index: 0;
  width: 100%;
  height: auto;
  will-change: filter;
  display: none;
  ${({ template }) => (template === 'needles' || template === 'trialworks') ? `
    border-radius: 8px;
    background: rgba(28, 28, 28, 0.05);
    overflow: hidden;
    top: 0;
    left: auto;
    right: -99px;
    ${mq_min('lg')} {
      min-height: ${getStyleSizes(844)};
    }
  ` : `
    filter: drop-shadow(16px 16px 20px rgba(53, 62, 91, 0.25));
    left: 0;
  `};
  &.active{
    opacity: 1;
    z-index: 1;
  }

  ${mq_min('lg')} {
    display: block;
  }
`

export const AccordionDetailImageStyled = styled('img')`
  width: 100%;
  height: 100%;
`;

export const AccordionIndicatorStyled = styled('svg')`
  width: ${getStyleSizes(32)};
  height: ${getStyleSizes(32)};
  fill: none;
`

export const AccordionImageContainerStyled = styled('div')`
  display: none;
  align-items: center;
  width: calc(100% - ${getStyleSizes(108)} - ${getStyleSizes(500)});
  position: relative;
  height: 100%;
  ${mq_min('lg')} {
    display: flex;
  }
}`

export const VideoMobilePlayerContainer = styled('div')`
  position: absolute;
  top: 55%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 1;
  display: block;
  width: ${getStyleSizes(70)};
  height: ${getStyleSizes(70)};
  ${mq_min('lg')} {
    display: none;
  }
`

export const VideoPlayerContainer = styled('div')`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 1;
  width: ${getStyleSizes(70)};
  height: ${getStyleSizes(70)};
`

export const CTAContainer = styled('div')<AccordionLayoutProps>`
  margin-top: ${getStyleSizes(32)};
  ${({ template }) => (template === 'needles') && `
    span{
      margin: 0!important;
    }
    svg{
      display: none !important;
    }
  `};
  ${mq_max('sm')} {
    button{
      ${({ template }) => (template === 'needles' || template === 'trialworks') ? `` : `
        width: 100%;
      `};
    }
  }
`