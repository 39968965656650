import styled from '@emotion/styled';

import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';
import { LayoutProps } from './testimonial-slider.interface';
import { 
  color_black, 
  color_eggshell,
  color_white
} from '@styles/colors';

export const TestimonialContainer = styled('div')<LayoutProps>`
  padding: ${getStyleSizes([20, 0, 64, 0])};
  ${({ slider_layout }) =>
    (slider_layout === 'needles' || slider_layout === 'trialworks') ? `
    background-color: ${color_white};
  ` : `
    background-color: ${color_eggshell.base};
  `};
  
  ${mq_min('md')} {
    padding: ${getStyleSizes([20, 0, 0, 0])};
  }
  ${mq_min('lg')} {
    ${({ slider_layout }) =>
      (slider_layout === 'needles' || slider_layout === 'trialworks') ? `
        padding: ${getStyleSizes([50, 0, 17, 0])};
    ` : `
        padding: ${getStyleSizes([37, 0, 17, 0])};
    `};
  }
`;

export const TestimonialInner = styled('div')`
  display: flex;
  align-items: flex-start;
  margin-top: ${getStyleSizes(5)};
  justify-content: space-between;
  flex-wrap: wrap;
  ${mq_min('lg')} {
    width: 100%;
  }
`;

export const HeadlineStyled = styled('h1')`
  color: ${color_black};
  text-align: center;
  font-size: ${getStyleSizes(32)};
  font-style: normal;
  font-weight: 600;
  line-height: ${getStyleSizes(38.4)};
  margin-top: ${getStyleSizes(36)};
  padding: ${getStyleSizes([0,20])};
  ${mq_min('md')} {
    font-size: ${getStyleSizes(40)};
    line-height: ${getStyleSizes(48)};
    margin-top: ${getStyleSizes(44)};
  }
`