import React from 'react';
import { TechStackProps } from './tech-stack.interface';
import * as Styled from './tech-stack.styles';
import CMSComponentWrapper from '../../shared/components/component-wrapper/component-wrapper';
import { PrimaryButton } from '@src/shared/components';

const TechStackComp: React.FC<TechStackProps> = ({
  uid,
  backgroundColor,
  isCentered,
  background_color,
  background_image,
  headline,
  template,
  mobile_logos,
  desktop_logos,
  tablet_logos,
  stack_cta
}) => {
  return (
    <>
      <Styled.TechStackContainer
        id={uid}
        background={backgroundColor}
        isCentered={isCentered}
        background_color={background_color?.colors}
        background_image={background_image?.url}
      >
        <CMSComponentWrapper maxWidth={1240}>
          <Styled.TechStackTitle template={template?.toLocaleLowerCase()}>{headline}</Styled.TechStackTitle>

          <Styled.TechContainerRow>
            <Styled.TechStackImage
              alt={headline}
              mobile_alt={mobile_logos?.title}
              mobile_image={mobile_logos?.url}
              tablet_alt={tablet_logos?.title}
              tablet_image={tablet_logos?.url}
              desktop_alt={desktop_logos?.title}
              desktop_image={desktop_logos?.url}
            />
          </Styled.TechContainerRow>
          {(stack_cta?.link_text && stack_cta?.link_url) && (
            <Styled.CTAContainer>
              <PrimaryButton
                  ariaLabel={stack_cta?.link_text}
                  path={stack_cta?.link_url}
                  label={`${stack_cta?.link_text}`}
                  size={'large'}
                  variant={'contained'}
                  icon={'text'}
              />
            </Styled.CTAContainer>
          )}
         
        </CMSComponentWrapper>
      </Styled.TechStackContainer>
    </>
  );
};

export { TechStackComp };
