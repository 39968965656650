
import { styled } from '@mui/material/styles';
import {  getStyleSizes } from '@styles/styles.utils';
import { mq_min } from '@styles/breakpoints';
import { Splide } from '@splidejs/react-splide';
import { TestimonialArrowDirectionProp, SwiperProps } from '../../testimonial-slider.interface';
import {
    color_black,
    color_dark_grey,
    color_text_grey,
    color_darkest_grey,
    color_light_grey,
    color_white,
    color_miami,
  } from '@styles/colors';
import { noTo_font } from '@src/styles/typography';
import { VariantProp } from '@shared/components/accordion/accordion.interface';
export const variants = {
    light: {
      borderColor: color_light_grey,
      headlineColor: color_darkest_grey,
      detailColor: color_dark_grey,
      iconColor: color_black,
    },
    mid: {
      borderColor: color_light_grey,
      headlineColor: color_darkest_grey,
      detailColor: color_dark_grey,
      iconColor: color_black,
    },
    dark: {
      borderColor: color_light_grey,
      headlineColor: color_white,
      detailColor: color_text_grey,
      iconColor: color_white,
    },
  };

export const TestimonialSplideStyled = styled(Splide)`
  position: relative;
  max-width: ${getStyleSizes(1240)};
  margin: auto;
  .splide__arrow--prev,
  .splide__arrow--next{
    opacity: 1;
    outline: 0;
    top: 40%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    width: ${getStyleSizes(34)};
    height: ${getStyleSizes(34)};
    border-radius: ${getStyleSizes(32)};
    border: 1px solid ${color_black};
    display: none;
    outline: none;
    box-shadow: none;
    ${mq_min('lg')} {
      display: block;
    }
    svg{
      display: none;
    }
  }
  .splide__arrow--prev{
    left: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M4.648 9.31047C4.7012 9.35 4.75693 9.38571 4.81467 9.41739L11.0144 13.3851L11.0373 13.4017L11.0776 13.4256C11.192 13.4879 11.3218 13.5119 11.4495 13.4945C11.5773 13.4771 11.6969 13.4191 11.7924 13.3282C11.8879 13.2374 11.9546 13.1181 11.9837 12.9863C12.0127 12.8545 12.0027 12.7165 11.955 12.5908L10.5986 8.0002L11.955 3.41147C12.0042 3.28187 12.0133 3.13927 11.9811 3.00387C11.9488 2.86854 11.8769 2.7472 11.7754 2.65714C11.6739 2.56707 11.5481 2.5128 11.4156 2.502C11.2831 2.4912 11.1508 2.5244 11.0373 2.59693L10.7986 2.74253L9.9405 3.29534L7.96633 4.55407L4.80767 6.5812C4.7534 6.61274 4.70067 6.6472 4.64973 6.6844C4.4492 6.83087 4.28533 7.02614 4.1722 7.25347C4.05907 7.4808 4 7.73353 4 7.99007C4 8.2466 4.05907 8.49934 4.1722 8.72667C4.28533 8.95407 4.4492 9.14927 4.64973 9.29573L4.648 9.31047Z' fill='%231A1A1A'/%3E%3C/svg%3E");
    &:hover{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M4.648 9.31047C4.7012 9.35 4.75693 9.38571 4.81467 9.41739L11.0144 13.3851L11.0373 13.4017L11.0776 13.4256C11.192 13.4879 11.3218 13.5119 11.4495 13.4945C11.5773 13.4771 11.6969 13.4191 11.7924 13.3282C11.8879 13.2374 11.9546 13.1181 11.9837 12.9863C12.0127 12.8545 12.0027 12.7165 11.955 12.5908L10.5986 8.0002L11.955 3.41147C12.0042 3.28187 12.0133 3.13927 11.9811 3.00387C11.9488 2.86854 11.8769 2.7472 11.7754 2.65714C11.6739 2.56707 11.5481 2.5128 11.4156 2.502C11.2831 2.4912 11.1508 2.5244 11.0373 2.59693L10.7986 2.74253L9.9405 3.29534L7.96633 4.55407L4.80767 6.5812C4.7534 6.61274 4.70067 6.6472 4.64973 6.6844C4.4492 6.83087 4.28533 7.02614 4.1722 7.25347C4.05907 7.4808 4 7.73353 4 7.99007C4 8.2466 4.05907 8.49934 4.1722 8.72667C4.28533 8.95407 4.4492 9.14927 4.64973 9.29573L4.648 9.31047Z' fill='%2325C9EF'/%3E%3C/svg%3E");
      border: 1px solid ${color_miami.base};
    }
  }
  .splide__arrow--next{
    right: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M11.352 6.68953C11.2988 6.65 11.2431 6.61429 11.1853 6.58261L4.98555 2.61491L4.96274 2.59832L4.92238 2.57436C4.80799 2.51213 4.67822 2.4881 4.55047 2.50552C4.42271 2.52292 4.30308 2.58094 4.20761 2.67177C4.11213 2.76261 4.04539 2.88193 4.01632 3.01373C3.98725 3.14554 3.99725 3.28352 4.04497 3.40918L5.40145 7.9998L4.04497 12.5885C3.9958 12.7181 3.98669 12.8607 4.01893 12.9961C4.05115 13.1315 4.1231 13.2528 4.22458 13.3429C4.32606 13.4329 4.45194 13.4872 4.58441 13.498C4.71687 13.5088 4.84923 13.4756 4.96274 13.4031L5.20139 13.2575L6.0595 12.7047L8.03367 11.4459L11.1923 9.4188C11.2466 9.38726 11.2993 9.3528 11.3503 9.3156C11.5508 9.16913 11.7147 8.97386 11.8278 8.74653C11.9409 8.5192 12 8.26647 12 8.00993C12 7.7534 11.9409 7.50066 11.8278 7.27333C11.7147 7.04593 11.5508 6.85073 11.3503 6.70427L11.352 6.68953Z' fill='%231A1A1A'/%3E%3C/svg%3E"); 
    &:hover{
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M11.352 6.68953C11.2988 6.65 11.2431 6.61429 11.1853 6.58261L4.98555 2.61491L4.96274 2.59832L4.92238 2.57436C4.80799 2.51213 4.67822 2.4881 4.55047 2.50552C4.42271 2.52292 4.30308 2.58094 4.20761 2.67177C4.11213 2.76261 4.04539 2.88193 4.01632 3.01373C3.98725 3.14554 3.99725 3.28352 4.04497 3.40918L5.40145 7.9998L4.04497 12.5885C3.9958 12.7181 3.98669 12.8607 4.01893 12.9961C4.05115 13.1315 4.1231 13.2528 4.22458 13.3429C4.32606 13.4329 4.45194 13.4872 4.58441 13.498C4.71687 13.5088 4.84923 13.4756 4.96274 13.4031L5.20139 13.2575L6.0595 12.7047L8.03367 11.4459L11.1923 9.4188C11.2466 9.38726 11.2993 9.3528 11.3503 9.3156C11.5508 9.16913 11.7147 8.97386 11.8278 8.74653C11.9409 8.5192 12 8.26647 12 8.00993C12 7.7534 11.9409 7.50066 11.8278 7.27333C11.7147 7.04593 11.5508 6.85073 11.3503 6.70427L11.352 6.68953Z' fill='%2325C9EF'/%3E%3C/svg%3E");
      border: 1px solid ${color_miami.base};
    }
  }
  .splide__track{
    margin: auto;
    max-width: ${getStyleSizes(360)};
    ${mq_min('md')} {
       max-width: ${getStyleSizes(768)};
    }
    ${mq_min('lg')} {
      max-width: ${getStyleSizes(1172)};
    }
  }
  .splide__pagination{
    position: static;
    margin-top: ${getStyleSizes(10)};
    margin-bottom: ${getStyleSizes(54)};
    ${mq_min('md')} {
      margin-top: ${getStyleSizes(14)};
      margin-bottom: ${getStyleSizes(62)};
    }
    ${mq_min('lg')} {
       margin-top: ${getStyleSizes(37)};
      margin-bottom: ${getStyleSizes(40)};
    }
    li {
      line-height: 1 !important;

      .splide__pagination__page{
        background: ${color_light_grey};
        position: relative;
        outline: 0;
        opacity: 1;
        margin: 3px 8px;
        width: ${getStyleSizes(10)};
        height: ${getStyleSizes(10)};

        &.is-active{
          background: ${color_miami.base};
          transform: none;
          opacity: 1;
          width: ${getStyleSizes(14)};
          height: ${getStyleSizes(14)};
        }
      }
    }
  }
`;

export const TestimonialCardStyled = styled('div')`
  display: flex;
  background: #fff;
  position: relative;
  flex-wrap: wrap;
  border-radius: ${getStyleSizes(6)};
  box-shadow: 0px 4px 29px 0px rgba(0, 0, 0, 0.08);
  border-radius: ${getStyleSizes([80,6])};
  padding: ${getStyleSizes([56,40,40])};
  min-height: ${getStyleSizes(398)};
  margin: ${getStyleSizes([24,20])};
  ${mq_min('md')} {
    padding: ${getStyleSizes([47,48,41])};
    flex-wrap: nowrap;
    min-height: ${getStyleSizes(232)};
  }
  ${mq_min('lg')} {
    max-width: 100%;
    margin: ${getStyleSizes([24,64])};
  }
`

export const TestimonialTextContainerStyled = styled('div')`
  margin-left: ${getStyleSizes(0)};
  position: relative;
  margin-top: ${getStyleSizes(10)};
  ${mq_min('md')} {
    margin-top: ${getStyleSizes(0)};
  }
  ${mq_min('lg')} {
    display: block;
  }
`
export const TestimonialTextQuoteStyled = styled('div')<VariantProp & SwiperProps>`
  color: ${({ variant }) => variants[variant]?.iconColor};
  font-weight: 300;
  padding-top: ${getStyleSizes(0.5)};
  margin-bottom: ${getStyleSizes(31)};
  min-height: ${getStyleSizes(240)};
  font-family: ${noTo_font};
  font-size: ${getStyleSizes(16)};
  line-height: ${getStyleSizes(24)};
  max-width: ${getStyleSizes(240)};
  ${mq_min('md')} {
    min-height: ${getStyleSizes(60)}; 
    max-width: 100%;
  }
  ${mq_min('lg')} {
    font-size: ${getStyleSizes(20)};
    margin-bottom: ${getStyleSizes(18)};
  }
  p{
    margin: 0;
  }
  p > span:nth-of-type(2){
    font-weight: 700;
  }
  > div:not(img){
    z-index: 1;
    position: relative;
  }
`
export const TestimonialTextQuoteIconStyled = styled('img')`
  position: absolute;
  width: ${getStyleSizes(84)};
  height: ${getStyleSizes(55)};
  background-repeat: no-repeat;
  top: ${getStyleSizes(-21)};
  left: ${getStyleSizes(-9)};
`
export const TestimonialCustomerInfoContainerStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  ${mq_min('lg')} {
    flex-wrap: nowrap;
  }
`
export const TestimonialCustomerInfoTextStyled = styled('div')`
  color: ${color_black};
  font-size: ${getStyleSizes(18)};
  font-weight: 600;
  line-height: ${getStyleSizes(27)};
  letter-spacing: 0;
  width: 100%;
  margin-bottom: ${getStyleSizes(16)};
  ${mq_min('lg')} {
    width: auto;
    margin-bottom: 0px;
    font-size: ${getStyleSizes(20)};
    line-height: ${getStyleSizes(24)};
  }
`
export const TestimonialLogoStyled = styled('img')`
  max-height: ${getStyleSizes(30)};
  ${mq_min('lg')} {
     max-height: ${getStyleSizes(40)};
  }
`