import styled from '@emotion/styled';
import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';
import { AccordionLayoutProps } from './accordion.interface';

export const AccordionContainer = styled.div<AccordionLayoutProps>`
  background-color: #ffffff;
  padding-top: ${getStyleSizes(63)};
  padding-bottom:  ${({ layout }) => layout === 'Vertical' ?  getStyleSizes(64) : getStyleSizes(43)};
  overflow: hidden;
  ${({ layout, template }) => layout === 'Horizontal' && `
    ${(template === 'needles' || template === 'trialworks') ? `
    padding-top: ${getStyleSizes(0)};
    ` : `
    padding-top: ${getStyleSizes(63)};
    `};
  `};
  ${mq_min('md')} {
    padding-top: ${getStyleSizes(80)};
    padding-bottom:  ${({ layout }) => layout === 'Vertical' ?  getStyleSizes(80) : getStyleSizes(92)};
    height: auto;
  }

  ${mq_min('lg')} {
    padding-top: ${getStyleSizes(99)};
    padding-bottom: ${getStyleSizes(102)};
    ${({ layout, template }) => layout === 'Vertical' && `
      ${(template === 'needles' || template === 'trialworks') ? `
        min-height: ${getStyleSizes(1120)};
      ` : `
        min-height: ${getStyleSizes(928)};
      `};
    `};

  }
`;

export const AccordionInner = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  ${mq_min('lg')} {
    padding: ${getStyleSizes([0, 32])};
  }
  ${mq_min('lg')} {
    width: 100%;
  }
`;
